import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { StyledLink, useMaster } from "@westcut/lib-client-common";
import { useRegisterMutation, UserRegister } from "../../Generated/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export const Register: React.FC = () => {
  const classes = useStyles();
  const { setJwtPersistant } = useMaster();
  const [register, { loading }] = useRegisterMutation({
    onCompleted(returnValue) {
      setJwtPersistant(returnValue?.register);
    },
  });

  const formik = useFormik<UserRegister & { passwordConfirm?: string }>({
    initialValues: {
      email: "",
      password: "",
      passwordConfirm: "",
      name: "",
      phone: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Email non valide")
        .max(255)
        .required("Email obligatoire"),
      password: yup.string().max(255).required("Mot de passe obligatoire"),
      passwordConfirm: yup.string().when("password", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("password")], "Mot de passe différent"),
      }),
      name: yup.string().required("Nom obligatoire"),
      phone: yup.string().required("Numéro de téléphone obligatoire"),
    }),
    onSubmit: (input) => {
      delete input.passwordConfirm;
      register({ variables: { input } });
    },
  });

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <Box mt={3} mb={1}>
            <Typography align="center" color="textPrimary" variant="h2">
              Créer un compte
            </Typography>
          </Box>
          <TextField
            id="name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            helperText={formik.touched.name && formik.errors.name}
            error={formik.touched.name && formik.errors.name !== undefined}
            label="Nom"
            type="string"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            id="phone"
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            helperText={formik.touched.phone && formik.errors.phone}
            error={formik.touched.phone && formik.errors.phone !== undefined}
            label="Numéro de téléphone"
            type="string"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            helperText={formik.touched.email && formik.errors.email}
            error={formik.touched.email && formik.errors.email !== undefined}
            label="Adresse mail"
            type="string"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            id="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            helperText={formik.touched.password && formik.errors.password}
            error={
              formik.touched.password && formik.errors.password !== undefined
            }
            label="Mot de passe"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            id="passwordConfirm"
            name="passwordConfirm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.passwordConfirm}
            helperText={
              formik.touched.passwordConfirm && formik.errors.passwordConfirm
            }
            error={
              formik.touched.passwordConfirm &&
              formik.errors.passwordConfirm !== undefined
            }
            label="Confirmer le mot de passe"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Grid container spacing={2} style={{ paddingTop: 20 }}>
            <Grid item xs={6}>
              <StyledLink to="/">
                <Button
                  color="secondary"
                  disabled={loading}
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  Fermer
                </Button>
              </StyledLink>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                disabled={loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Créer un compte
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};
