import React from "react";
import { Button, DialogActions, Grid, Typography } from "@material-ui/core";
import {
  MyDatePicker,
  SingleSelect,
  formatPrice,
  toast,
} from "@westcut/lib-client-common";
import { humanReadableTime } from "@westcut/lib-common-tools";
import {
  OperatorFragment,
  AppointmentTypeFragment,
  useCreateAppointmentMutation,
  usePossibilitiesWithServiceTypeLazyQuery,
} from "../../../Generated/graphql";

interface Props {
  onSuccess: () => void;
  operators: OperatorFragment[];
  appointmentTypes: AppointmentTypeFragment[];
}

export const Create: React.FC<Props> = ({
  onSuccess,
  operators,
  appointmentTypes,
}) => {
  const [getOperatorId, setOperatorId] = React.useState<number>(
    operators[0].id,
  );
  const [getAppointmentTypeId, setAppointmentTypeId] = React.useState<number>(
    appointmentTypes[0].id,
  );
  const [getDay, setDay] = React.useState<Date>(new Date());
  const [getStart, setStart] = React.useState<Date | undefined>();

  const [loadData, { data, loading: LoadingPossibilities }] =
    usePossibilitiesWithServiceTypeLazyQuery({ onCompleted: () => setStart(undefined) });
  const [create, { loading }] = useCreateAppointmentMutation();

  React.useEffect(() => {
    loadData({ variables: { day: getDay, operatorId: getOperatorId, appointmentTypeId: getAppointmentTypeId } });
  }, [loadData, getDay, getOperatorId, getAppointmentTypeId]);

  const slots =
    data?.possibilitiesWithServiceType.map((possibility) => new Date(possibility)) || [];

  const processCreate = () => {
    if (!getStart) {
      return;
    }
    if (!getAppointmentTypeId) {
      toast("Veuillez selectionner un type de rendez-vous");
      return;
    }
    create({
      variables: {
        input: {
          from: getStart,
          operatorId: getOperatorId,
          appointmentTypeId: getAppointmentTypeId,
        },
      },
      update: () => {
        setStart(undefined);
        loadData({ variables: { day: getDay, operatorId: getOperatorId, appointmentTypeId: getAppointmentTypeId } });
        onSuccess();
      },
    });
  };
  return (
    <>
      <Typography color="textSecondary" variant="h4" gutterBottom>
        Prendre un rendez-vous
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} style={{ marginBottom: 30 }}>
          <MyDatePicker value={getDay} onChange={setDay} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleSelect
            disableClearable
            currentValue={getOperatorId}
            label="Coiffeur"
            setNewValue={(value) => setOperatorId(value || operators[0].id)}
            options={operators.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleSelect
            disableClearable
            currentValue={getAppointmentTypeId}
            label="Service"
            setNewValue={(value) =>
              setAppointmentTypeId(value || appointmentTypes[0].id)
            }
            options={appointmentTypes.map(({ id, name, price }) => ({
              label: `${name} (${formatPrice(price)})`,
              value: id,
            }))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {slots.length !== 0 ? (
            <SingleSelect
              disableClearable
              currentValue={getStart}
              label="Heure de début"
              setNewValue={(value) => setStart(value)}
              options={slots.map((slot) => ({
                label: humanReadableTime(slot),
                value: slot,
              }))}
            />
          ) : (
            <Typography color="textSecondary">
              {LoadingPossibilities
                ? "Chargement"
                : "Aucun horaire disponible pour ce jour"}
            </Typography>
          )}
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          onClick={processCreate}
          color="primary"
          disabled={loading || !getStart || new Date() > getStart}
          variant="contained"
        >
          Prendre rdv
        </Button>
      </DialogActions>
    </>
  );
};
