import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { StyledLink, toast } from "@westcut/lib-client-common";
import { usePasswordForgottenMutation } from "../../Generated/graphql";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export const PasswordForgotten: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const [forget, { loading }] = usePasswordForgottenMutation({
    onCompleted(returnValue) {
      if (returnValue?.passwordForgotten === true) {
        toast("Email envoyé");
        history.push("/");
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Email non valide")
        .max(255)
        .required("Email obligatoire"),
    }),
    onSubmit: (input) => {
      forget({ variables: { email: input.email } });
    },
  });

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <Box mt={3} mb={1}>
            <Typography align="center" color="textPrimary" variant="h2">
              J'ai oublié mon mot de passe
            </Typography>
          </Box>
          <TextField
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            helperText={formik.touched.email && formik.errors.email}
            error={formik.touched.email && formik.errors.email !== undefined}
            label="Adresse mail (*)"
            type="string"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Grid container spacing={2} style={{ paddingTop: 20 }}>
            <Grid item xs={6}>
              <StyledLink to="/">
                <Button
                  color="secondary"
                  disabled={loading}
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  Annuler
                </Button>
              </StyledLink>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                disabled={loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Confirmer
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};
