import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import headBackground from "./Assets/headBackground.jpg";

const center: CSSProperties = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
};

export const useHeadStyle = makeStyles((theme) => ({
  root: {
    backgroundImage: ` linear-gradient(rgba(9, 9, 13, 0.87),rgba(9, 9, 13, 0.87)),url(${headBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    minHeight: "100vh",
    paddingTop: 70,
  },
  logo: {
    maxWidth: 250,
    ...center,
  },
  text: {
    color: "white",
    fontFamily: "blackhawkregular",
  },
  button: {
    ...center,
  },
}));

export const useScheduleStyle = makeStyles((theme) => ({
  root: {
    paddingTop: 70,
    paddingBottom: 70,
  },
  western: {
    fontSize: 72,
    fontFamily: "blackhawkregular",
  },
  clock: { fontSize: 48, textAlign: "center", width: "100%" },
  timeSlot: {
    padding: 20,
  },
}));
