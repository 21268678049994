import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import {
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import {
  formatPrice,
  StyledLink,
  toast,
  useMaster,
} from "@westcut/lib-client-common";
import { Create } from "./Create";
import { List } from "./List";
import { useGetAllQuery } from "../../../Generated/graphql";

export const Profile: React.FC = () => {
  const history = useHistory();
  const { logout } = useMaster();
  const { data, refetch } = useGetAllQuery();

  if (!data?.me) {
    return <></>;
  }

  if (!data?.me.phone || data?.me.phone === "") {
    toast("Veuillez rajouter votre numéro de téléphone (obl.)");
    return <Redirect to="/update_profile" />;
  }

  if (!data?.me.name || data?.me.name === "") {
    toast("Veuillez rajouter votre nom (obl.)");
    return <Redirect to="/update_profile" />;
  }

  if (!data.me.trusted) {
    history.push("/verification");
  }

  return (
    <>
      <DialogContent>
        <Create
          onSuccess={() => refetch()}
          appointmentTypes={data.appointmentTypes}
          operators={data.operators}
        />
        <Typography color="textSecondary" variant="h4" gutterBottom>
          Solde actuel {formatPrice(data.me.balance)}
        </Typography>
        {data.appointments.length !== 0 && (
          <List onSuccess={() => refetch()} appointments={data.appointments} />
        )}
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <StyledLink to="/">
          <Button variant="contained" color="secondary" onClick={logout}>
            Se déconnecter
          </Button>
        </StyledLink>
        <StyledLink to="/update_profile">
          <Button variant="contained" color="primary">
            Modifier mes informations
          </Button>
        </StyledLink>
        <StyledLink to="/">
          <Button variant="contained" color="default">
            Fermer
          </Button>
        </StyledLink>
      </DialogActions>
    </>
  );
};
