import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Appointment = {
  __typename?: "Appointment";
  appointmentType: AppointmentType;
  canceled?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  from: Scalars["DateTime"];
  id: Scalars["Float"];
  operator: Operator;
  paid?: Maybe<Scalars["DateTime"]>;
  to: Scalars["DateTime"];
  user?: Maybe<User>;
  userName?: Maybe<Scalars["String"]>;
};

export type AppointmentCreate = {
  appointmentTypeId: Scalars["Float"];
  from: Scalars["DateTime"];
  operatorId: Scalars["Float"];
};

export type AppointmentType = {
  __typename?: "AppointmentType";
  appointments: Array<Appointment>;
  disabled: Scalars["Boolean"];
  duration: Scalars["Float"];
  id: Scalars["Float"];
  name: Scalars["String"];
  price: Scalars["Float"];
};

export type Block = {
  __typename?: "Block";
  createdAt: Scalars["DateTime"];
  from: Scalars["DateTime"];
  id: Scalars["Float"];
  operator: Operator;
  to: Scalars["DateTime"];
};

export type Mutation = {
  __typename?: "Mutation";
  appointmentCancel: Appointment;
  appointmentCreate: Appointment;
  login: Scalars["String"];
  passwordForgotten: Scalars["Boolean"];
  passwordReset: Scalars["String"];
  register: Scalars["String"];
  userUpdate: User;
};

export type MutationAppointmentCancelArgs = {
  id: Scalars["Float"];
};

export type MutationAppointmentCreateArgs = {
  input: AppointmentCreate;
};

export type MutationLoginArgs = {
  input: UserAuth;
};

export type MutationPasswordForgottenArgs = {
  email: Scalars["String"];
};

export type MutationPasswordResetArgs = {
  id: Scalars["Float"];
  newPassword: Scalars["String"];
  resetKey: Scalars["String"];
};

export type MutationRegisterArgs = {
  input: UserRegister;
};

export type MutationUserUpdateArgs = {
  input: UserUpdate;
};

export type Operator = {
  __typename?: "Operator";
  appointments: Array<Appointment>;
  blocks: Array<Block>;
  id: Scalars["Float"];
  isAdmin: Scalars["Boolean"];
  name: Scalars["String"];
  schedule: Scalars["String"];
};

export type Payment = {
  __typename?: "Payment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Float"];
  user: User;
};

export type Query = {
  __typename?: "Query";
  appointments: Array<Appointment>;
  appointmentTypes: Array<AppointmentType>;
  blocks: Array<Block>;
  me?: Maybe<User>;
  operators: Array<Operator>;
  possibilities: Array<Scalars["DateTime"]>;
  possibilitiesWithServiceType: Array<Scalars["DateTime"]>;
};

export type QueryBlocksArgs = {
  operatorId?: Maybe<Scalars["Float"]>;
};

export type QueryPossibilitiesArgs = {
  day: Scalars["DateTime"];
  operatorId: Scalars["Float"];
};

export type QueryPossibilitiesWithServiceTypeArgs = {
  appointmentTypeId: Scalars["Float"];
  day: Scalars["DateTime"];
  operatorId: Scalars["Float"];
};

export type User = {
  __typename?: "User";
  appointments: Array<Appointment>;
  balance: Scalars["Float"];
  email: Scalars["String"];
  id: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  payments: Array<Payment>;
  phone?: Maybe<Scalars["String"]>;
  trusted: Scalars["Boolean"];
};

export type UserAuth = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type UserRegister = {
  email: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
};

export type UserUpdate = {
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type PossibilitiesQueryVariables = Exact<{
  day: Scalars["DateTime"];
  operatorId: Scalars["Float"];
}>;

export type PossibilitiesQuery = (
  { __typename?: "Query" }
  & Pick<Query, "possibilities">
);

export type PossibilitiesWithServiceTypeQueryVariables = Exact<{
  day: Scalars["DateTime"];
  operatorId: Scalars["Float"];
  appointmentTypeId: Scalars["Float"];
}>;

export type PossibilitiesWithServiceTypeQuery = (
  { __typename?: "Query" }
  & Pick<Query, "possibilitiesWithServiceType">
);

export type CreateAppointmentMutationVariables = Exact<{
  input: AppointmentCreate;
}>;

export type CreateAppointmentMutation = (
  { __typename?: "Mutation" }
  & { appointmentCreate: (
    { __typename?: "Appointment" }
    & Pick<Appointment, "id">
  ) }
);

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type CancelAppointmentMutation = (
  { __typename?: "Mutation" }
  & { appointmentCancel: (
    { __typename?: "Appointment" }
    & Pick<Appointment, "id">
  ) }
);

export type GetAllQueryVariables = Exact<{ [key: string]: never; }>;

export type GetAllQuery = (
  { __typename?: "Query" }
  & { me?: Maybe<(
    { __typename?: "User" }
    & UserFragment
  )>, appointmentTypes: Array<(
    { __typename?: "AppointmentType" }
    & AppointmentTypeFragment
  )>, operators: Array<(
    { __typename?: "Operator" }
    & OperatorFragment
  )>, appointments: Array<(
    { __typename?: "Appointment" }
    & AppointmentFragment
  )> }
);

export type LoginMutationVariables = Exact<{
  input: UserAuth;
}>;

export type LoginMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "login">
);

export type RegisterMutationVariables = Exact<{
  input: UserRegister;
}>;

export type RegisterMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "register">
);

export type UserUpdateMutationVariables = Exact<{
  input: UserUpdate;
}>;

export type UserUpdateMutation = (
  { __typename?: "Mutation" }
  & { userUpdate: (
    { __typename?: "User" }
    & UserFragment
  ) }
);

export type PasswordResetMutationVariables = Exact<{
  id: Scalars["Float"];
  newPassword: Scalars["String"];
  resetKey: Scalars["String"];
}>;

export type PasswordResetMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "passwordReset">
);

export type PasswordForgottenMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type PasswordForgottenMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "passwordForgotten">
);

export type UserFragment = (
  { __typename?: "User" }
  & Pick<User, "id" | "email" | "name" | "phone" | "balance" | "trusted">
);

export type AppointmentFragment = (
  { __typename?: "Appointment" }
  & Pick<Appointment, "id" | "from" | "canceled">
  & { operator: (
    { __typename?: "Operator" }
    & OperatorFragment
  ), appointmentType: (
    { __typename?: "AppointmentType" }
    & AppointmentTypeFragment
  ) }
);

export type AppointmentTypeFragment = (
  { __typename?: "AppointmentType" }
  & Pick<AppointmentType, "id" | "name" | "price">
);

export type OperatorFragment = (
  { __typename?: "Operator" }
  & Pick<Operator, "id" | "name" | "schedule">
);

export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  name
  phone
  balance
  trusted
}
    `;
export const OperatorFragmentDoc = gql`
    fragment Operator on Operator {
  id
  name
  schedule
}
    `;
export const AppointmentTypeFragmentDoc = gql`
    fragment AppointmentType on AppointmentType {
  id
  name
  price
}
    `;
export const AppointmentFragmentDoc = gql`
    fragment Appointment on Appointment {
  id
  from
  canceled
  operator {
    ...Operator
  }
  appointmentType {
    ...AppointmentType
  }
}
    ${OperatorFragmentDoc}
${AppointmentTypeFragmentDoc}`;
export const PossibilitiesDocument = gql`
    query Possibilities($day: DateTime!, $operatorId: Float!) {
  possibilities(day: $day, operatorId: $operatorId)
}
    `;

/**
 * __usePossibilitiesQuery__
 *
 * To run a query within a React component, call `usePossibilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossibilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossibilitiesQuery({
 *   variables: {
 *      day: // value for 'day'
 *      operatorId: // value for 'operatorId'
 *   },
 * });
 */
export function usePossibilitiesQuery(baseOptions?: Apollo.QueryHookOptions<PossibilitiesQuery, PossibilitiesQueryVariables>) {
        return Apollo.useQuery<PossibilitiesQuery, PossibilitiesQueryVariables>(PossibilitiesDocument, baseOptions);
      }
export function usePossibilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PossibilitiesQuery, PossibilitiesQueryVariables>) {
          return Apollo.useLazyQuery<PossibilitiesQuery, PossibilitiesQueryVariables>(PossibilitiesDocument, baseOptions);
        }
export type PossibilitiesQueryHookResult = ReturnType<typeof usePossibilitiesQuery>;
export type PossibilitiesLazyQueryHookResult = ReturnType<typeof usePossibilitiesLazyQuery>;
export type PossibilitiesQueryResult = Apollo.QueryResult<PossibilitiesQuery, PossibilitiesQueryVariables>;
export const PossibilitiesWithServiceTypeDocument = gql`
    query PossibilitiesWithServiceType($day: DateTime!, $operatorId: Float!, $appointmentTypeId: Float!) {
  possibilitiesWithServiceType(day: $day, operatorId: $operatorId, appointmentTypeId: $appointmentTypeId)
}
    `;

/**
 * __usePossibilitiesWithServiceTypeQuery__
 *
 * To run a query within a React component, call `usePossibilitiesWithServiceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossibilitiesWithServiceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossibilitiesWithServiceTypeQuery({
 *   variables: {
 *      day: // value for 'day'
 *      operatorId: // value for 'operatorId'
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *   },
 * });
 */
export function usePossibilitiesWithServiceTypeQuery(baseOptions?: Apollo.QueryHookOptions<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>) {
        return Apollo.useQuery<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>(PossibilitiesWithServiceTypeDocument, baseOptions);
      }
export function usePossibilitiesWithServiceTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>) {
          return Apollo.useLazyQuery<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>(PossibilitiesWithServiceTypeDocument, baseOptions);
        }
export type PossibilitiesWithServiceTypeQueryHookResult = ReturnType<typeof usePossibilitiesWithServiceTypeQuery>;
export type PossibilitiesWithServiceTypeLazyQueryHookResult = ReturnType<typeof usePossibilitiesWithServiceTypeLazyQuery>;
export type PossibilitiesWithServiceTypeQueryResult = Apollo.QueryResult<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>;
export const CreateAppointmentDocument = gql`
    mutation CreateAppointment($input: AppointmentCreate!) {
  appointmentCreate(input: $input) {
    id
  }
}
    `;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<CreateAppointmentMutation, CreateAppointmentMutationVariables>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>) {
        return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(CreateAppointmentDocument, baseOptions);
      }
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation CancelAppointment($id: Float!) {
  appointmentCancel(id: $id) {
    id
  }
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, baseOptions);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const GetAllDocument = gql`
    query GetAll {
  me {
    ...User
  }
  appointmentTypes {
    ...AppointmentType
  }
  operators {
    ...Operator
  }
  appointments {
    ...Appointment
  }
}
    ${UserFragmentDoc}
${AppointmentTypeFragmentDoc}
${OperatorFragmentDoc}
${AppointmentFragmentDoc}`;

/**
 * __useGetAllQuery__
 *
 * To run a query within a React component, call `useGetAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllQuery(baseOptions?: Apollo.QueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
        return Apollo.useQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, baseOptions);
      }
export function useGetAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
          return Apollo.useLazyQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, baseOptions);
        }
export type GetAllQueryHookResult = ReturnType<typeof useGetAllQuery>;
export type GetAllLazyQueryHookResult = ReturnType<typeof useGetAllLazyQuery>;
export type GetAllQueryResult = Apollo.QueryResult<GetAllQuery, GetAllQueryVariables>;
export const LoginDocument = gql`
    mutation Login($input: UserAuth!) {
  login(input: $input)
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($input: UserRegister!) {
  register(input: $input)
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($input: UserUpdate!) {
  userUpdate(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, baseOptions);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($id: Float!, $newPassword: String!, $resetKey: String!) {
  passwordReset(id: $id, newPassword: $newPassword, resetKey: $resetKey)
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newPassword: // value for 'newPassword'
 *      resetKey: // value for 'resetKey'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, baseOptions);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const PasswordForgottenDocument = gql`
    mutation PasswordForgotten($email: String!) {
  passwordForgotten(email: $email)
}
    `;
export type PasswordForgottenMutationFn = Apollo.MutationFunction<PasswordForgottenMutation, PasswordForgottenMutationVariables>;

/**
 * __usePasswordForgottenMutation__
 *
 * To run a mutation, you first call `usePasswordForgottenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordForgottenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordForgottenMutation, { data, loading, error }] = usePasswordForgottenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordForgottenMutation(baseOptions?: Apollo.MutationHookOptions<PasswordForgottenMutation, PasswordForgottenMutationVariables>) {
        return Apollo.useMutation<PasswordForgottenMutation, PasswordForgottenMutationVariables>(PasswordForgottenDocument, baseOptions);
      }
export type PasswordForgottenMutationHookResult = ReturnType<typeof usePasswordForgottenMutation>;
export type PasswordForgottenMutationResult = Apollo.MutationResult<PasswordForgottenMutation>;
export type PasswordForgottenMutationOptions = Apollo.BaseMutationOptions<PasswordForgottenMutation, PasswordForgottenMutationVariables>;