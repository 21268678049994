import React from "react";
import { Button, Card, CardHeader, Grid } from "@material-ui/core";
import { dayRatio, humanReadableDate } from "@westcut/lib-common-tools";
import { AppointmentFragment } from "../../../Generated/graphql";

interface Props {
  appointment: AppointmentFragment;
  processCancel: (id: number) => void;
}

export const AppointmentCard: React.FC<Props> = ({
  appointment,
  processCancel,
}) => {
  const title = `${appointment.appointmentType.name} - ${appointment.operator.name}`;
  const subheader = humanReadableDate(new Date(appointment.from));

  return (
    <Grid item xs={12} md={6}>
      <Card>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <Button
              variant="contained"
              color="secondary"
              onClick={() => processCancel(appointment.id)}
            >
              Annuler
              {new Date(appointment.from).valueOf() - new Date().valueOf() <
                dayRatio && " (remboursement réduit)"}
            </Button>
          }
        />
      </Card>
    </Grid>
  );
};
