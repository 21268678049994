import React from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { Dialog, useMediaQuery, useTheme } from "@material-ui/core";
import { useMaster } from "@westcut/lib-client-common";
import { Login } from "./Dialog/Login";
import { Profile } from "./Dialog/Profile";
import { UpdateProfile } from "./Dialog/Profile/UpdateProfile";
import { Register } from "./Dialog/Register";
import { Untrusted } from "./Dialog/Untrusted";
import { PasswordForgotten } from "./Dialog/PasswordForgotten";
import { PasswordReset } from "./Dialog/PasswordReset";

export const MyDialog: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { getJwt } = useMaster();

  const close = () => {
    history.push("/");
  };

  return (
    <Switch>
      <Route exact path={"/"} />
      <Dialog
        fullScreen={fullScreen}
        open
        onClose={close}
        fullWidth={!!getJwt}
        maxWidth="md"
      >
        <Switch>
          <Route exact path={"/login"}>
            {getJwt && <Redirect to="/profile" />}
            <Login />
          </Route>
          <Route exact path={"/register"}>
            {getJwt && <Redirect to="/profile" />}
            <Register />
          </Route>
          <Route exact path={"/password/forgotten"}>
            {getJwt && <Redirect to="/profile" />}
            <PasswordForgotten />
          </Route>
          <Route exact path={"/password/reset/:id/:token"}>
            {getJwt && <Redirect to="/profile" />}
            <PasswordReset />
          </Route>
          <Route exact path={"/profile"}>
            {!getJwt && <Redirect to="/login" />}
            <Profile />
          </Route>
          <Route exact path={"/update_profile"}>
            {!getJwt && <Redirect to="/login" />}
            <UpdateProfile />
          </Route>
          <Route exact path={"/verification"}>
            {!getJwt && <Redirect to="/login" />}
            <Untrusted />
          </Route>
        </Switch>
      </Dialog>
    </Switch>
  );
};
