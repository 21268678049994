import React from "react";
import GoogleMapReact from "google-map-react";
import logo from "../../Assets/logo.png";

const Marker = ({ lat }: { lat: number; lng: number }) => (
  <img src={logo} alt="marker" height={50} />
);

export const Map: React.FC = () => {
  return (
    <div style={{ height: 380, width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyD-AMG7VZMIb5VuOQyGSDpCPQCDQgpJ2sQ" }}
        defaultCenter={{
          lat: 46.77635,
          lng: 6.63715,
        }}
        defaultZoom={18}
      >
        <Marker lat={46.77635} lng={6.63715} />
      </GoogleMapReact>
    </div>
  );
};
