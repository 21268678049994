import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { StyledLink, useMaster } from "@westcut/lib-client-common";
import { useGetAllQuery } from "../../Generated/graphql";

export const Untrusted: React.FC = () => {
  const history = useHistory();
  const { logout } = useMaster();
  const { data } = useGetAllQuery();

  if (!data?.me) {
    return <></>;
  }

  if (data.me.trusted) {
    history.push("/profile");
  }

  return (
    <>
      <DialogContent>
        <Typography variant="h3">En attente de vérification</Typography>
        <Typography variant="body1">
          Bonjour, afin d’éviter les réservations factices, veuillez confirmer
          la création de votre compte par téléphone au{" "}
          <a
            href="tel:+41 79 677 22 42"
            style={{ color: "black", textDecoration: "none" }}
          >
            +41 79 677 22 42
          </a>{" "}
          ou en passant au magasin avant de pouvoir réserver. Veuillez nous
          mentionner votre numéro client{" "}
          <span style={{ fontWeight: "bold" }}>({data.me.id})</span> ou votre
          adresse email{" "}
          <span style={{ fontWeight: "bold" }}>({data.me.email})</span>.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <StyledLink to="/">
          <Button variant="contained" color="secondary" onClick={logout}>
            Se déconnecter
          </Button>
        </StyledLink>
        <StyledLink to="/">
          <Button variant="contained" color="default">
            Fermer
          </Button>
        </StyledLink>
      </DialogActions>
    </>
  );
};
