import React from "react";
import divider from "../../Assets/divider.png";

export const Divider: React.FC = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${divider})`,
        backgroundSize: "cover",
        width: 100,
        height: 20,
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        zIndex: 99,
        position: "relative",
      }}
    />
  );
};
