import React from "react";
import { Button, Typography } from "@material-ui/core";
import { StyledLink, useMaster } from "@westcut/lib-client-common";
import { useHeadStyle } from "../../styles";
import logo from "../../Assets/logo.png";

export const Head: React.FC = () => {
  const { getJwt } = useMaster();
  const classes = useHeadStyle();
  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <Typography
        align="center"
        className={classes.text}
        variant="h1"
        style={{ fontSize: 96 }}
      >
        West Cut
      </Typography>
      <Typography
        align="center"
        className={classes.text}
        variant="h2"
        style={{ fontSize: 48 }}
      >
        BARBERSHOP
      </Typography>
      {getJwt ? (
        <StyledLink to="profile">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Mon compte
          </Button>
        </StyledLink>
      ) : (
        <StyledLink to="login">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Se connecter
          </Button>
        </StyledLink>
      )}
    </div>
  );
};
