import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AppointmentCard } from "./AppointmentCard";
import {
  AppointmentFragment,
  useCancelAppointmentMutation,
} from "../../../Generated/graphql";

interface Props {
  appointments: AppointmentFragment[];
  onSuccess: () => void;
}

export const List: React.FC<Props> = ({ onSuccess, appointments }) => {
  const [cancel] = useCancelAppointmentMutation();

  const processCancel = (id: number) => {
    cancel({
      variables: { id },
      update: () => {
        onSuccess();
      },
    });
  };
  return (
    <>
      <Typography color="textSecondary" variant="h4" gutterBottom>
        Rendez-vous :
      </Typography>
      <Grid container spacing={2}>
        {appointments.map((appointment, index) => (
          <AppointmentCard
            key={index}
            appointment={appointment}
            processCancel={processCancel}
          />
        ))}
      </Grid>
    </>
  );
};
