import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import { useScheduleStyle } from "../../styles";
import { Divider } from "./Divider";

export const Schedule: React.FC = () => {
  const classes = useScheduleStyle();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          WESTCUT
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2" align="center" className={classes.western}>
          HORAIRES
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <AccessTime className={classes.clock} />
      </Grid>
      <Grid item xs={12} md={4} className={classes.timeSlot}>
        <Typography variant="h4" align="center">
          LUNDI ET DIMANCHE
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary">
          Fermé
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.timeSlot}>
        <Typography variant="h4" align="center">
          MARDI À VENDREDI
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary">
          07h30 à 18h30
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.timeSlot}>
        <Typography variant="h4" align="center">
          SAMEDI
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary">
          07h45 à 14h00
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Avec et sans rendez-vous, appelez-nous : +41 79 677 22 42
        </Typography>
        <Typography variant="h4" align="center">
          Ou rendez nous visite 29 Rue Roger-de-Guimps, 1400 Yverdon-les-Bains.
        </Typography>
      </Grid>
    </Grid>
  );
};
