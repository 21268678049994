import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { StyledLink, useMaster } from "@westcut/lib-client-common";
import { UserAuth, useLoginMutation } from "../../Generated/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export const Login: React.FC = () => {
  const classes = useStyles();
  const { setJwtPersistant } = useMaster();
  const [login, { loading }] = useLoginMutation({
    onCompleted(returnValue) {
      setJwtPersistant(returnValue?.login);
    },
  });

  const formik = useFormik<UserAuth>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Email non valide")
        .max(255)
        .required("Email obligatoire"),
      password: yup.string().max(255).required("Mot de passe obligatoire"),
    }),
    onSubmit: (input) => {
      login({ variables: { input } });
    },
  });

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <Box mt={3} mb={1}>
            <Typography align="center" color="textPrimary" variant="h2">
              Se connecter
            </Typography>
          </Box>
          <TextField
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            helperText={formik.touched.email && formik.errors.email}
            error={formik.touched.email && formik.errors.email !== undefined}
            label="Adresse mail"
            type="string"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            id="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            helperText={formik.touched.password && formik.errors.password}
            error={
              formik.touched.password && formik.errors.password !== undefined
            }
            label="Mot de passe"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Grid container spacing={2} style={{ paddingTop: 20 }}>
            <Grid item xs={12}>
              <StyledLink to="/password/forgotten">
                <Button disabled={loading} variant="text">
                  J'ai oublié mon mot de passe
                </Button>
              </StyledLink>
            </Grid>
            <Grid item xs={12}>
              <StyledLink to="/register">
                <Button disabled={loading} variant="text">
                  Créer un compte
                </Button>
              </StyledLink>
            </Grid>
            <Grid item xs={6}>
              <StyledLink to="/">
                <Button
                  color="secondary"
                  disabled={loading}
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  Fermer
                </Button>
              </StyledLink>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                disabled={loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Se connecter
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};
