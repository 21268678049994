import React from "react";
import ReactDOM from "react-dom";
import { MasterProvider, serviceWorker } from "@westcut/lib-client-common";
import { env } from "./env";
import { Head } from "./Component/Static/Head";
import { Schedule } from "./Component/Static/Schedule";
import { Map } from "./Component/Static/Map";
import { Footer } from "./Component/Static/Footer";
import { MyDialog } from "./Component/MyDialog";
import "./style.css";

const Page = () => {
  return (
    <>
      <MyDialog />
      <Head />
      <Schedule />
      <Map />
      <Footer />
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <MasterProvider uri={env.REACT_APP_API_URL}>
      <Page />
    </MasterProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
